import React from "react";
import styles from "./contact.module.css";

import ContactForm from "../molecules/Contact Form/contact-form";
import Hamburger from "../molecules/Hamburger/hamb";

import ContactPhoto from "../../assets/contact.jpg";
import Header from "../organisms/header";
import SocialsBlack from "../molecules/socials/socialsBlack";
import FadeInElement from "../atoms/FadeInElement";

const ContactPage = () => {
    const getFacebookLink = () => {
        const desktopURL = "https://www.facebook.com/BrandinhoMusic/events?ref=browser";
        const mobileURL = "https://m.facebook.com/BrandinhoMusic/events?ref=browser";
        return isMobileDevice() ? mobileURL : desktopURL;
    };

    const isMobileDevice = () => {
        return /Mobi|Android/i.test(navigator.userAgent);
    };

    return (
        <div className={styles.contact}>
            <Header />
            <FadeInElement>
                <h2 className={styles.heading}>Contact</h2>
                <p>Hi! If you would like to get in touch, please use the form below.</p>
                <div className={styles.formAndPhoto}>
                    <ContactForm />
                    <div className={styles.contactImgCont}>
                        <img src={ContactPhoto} alt="Brandon smiling, leaning on a piano." />
                    </div>
                </div>
                <div className={styles.facebookDates}>
                    <p>For my latest performance dates please visit <a href={getFacebookLink()} target="_blank" rel="noopener noreferrer">my Facebook page.</a></p>
                </div>
                <div><p> </p></div>
            </FadeInElement>
            <SocialsBlack />
            <Hamburger />
        </div>
    );
};

export default ContactPage;