import React, { useState, useEffect } from "react";
import styles from "./hamb.module.css";
import Menu from "../../atoms/menu";
import Logo from "../../../assets/favicon.png";

const Hamburger = () => {
    const [scrolled, setScrolled] = useState(false);

    const handleScroll = () => {
        if (window.scrollY > 80) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className={`${styles.Hamb} ${scrolled ? styles.scrolled : ''}`}>
            <label className={styles.hamburgerMenu}>
                <input type="checkbox" id="HambCheck"/>
            </label>
            <aside className={styles.sidebar}>
                <nav>
                    <img src={Logo} alt="Outline of three piano keys" />
                    <Menu />
                </nav>
            </aside>
        </div>
    );
};

export default Hamburger;
