import React from 'react';
import styles from './hero.module.css';

import SocialsBar from '../molecules/socials/socials';
import FadeInElement from '../atoms/FadeInElement';
import Navbar from '../molecules/Navbar/navbar';
import Hamburger from '../molecules/Hamburger/hamb';


const HeroPage = () => {
    return (
            <div className={styles.hero}>
                <FadeInElement>
                    <Navbar className={styles.navbar} />
                    
                    <SocialsBar />
                    <Hamburger />
                </FadeInElement>
            </div>
    );
};

export default HeroPage;