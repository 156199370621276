import React, { useState } from "react";
import styles from "./contact-form.module.css";

const ContactForm = () => {
    const [result, setResult] = useState("");

    const onSubmit = async (event) => {
        event.preventDefault();
        setResult("Sending....");
        const formData = new FormData(event.target);

        formData.append("access_key", "01aba719-a9ef-4dfa-9247-844e67495d3a");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            body: formData
        });

        const data = await response.json();

        if (data.success) {
            setResult("Form Submitted Successfully");
            event.target.reset();
        } else {
            console.log("Error", data);
            setResult(data.message);
        }
    };

    const getResultClass = () => {
        if (result === "Sending....") {
            return styles.sending;
        } else if (result === "Thank you for your message.") {
            return styles.success;
        } else if (result) {
            return styles.error;
        }
        return "";
    };

    return (
        <div>
            <form className={styles.contactForm} onSubmit={onSubmit}>
                <input type="text" placeholder="Name" id="name" name="name" required />
                <input type="email" placeholder="Email" id="email" name="email" required />
                <textarea placeholder="Message" id="message" name="message" required />
                <input type="submit" value="Send" />
            </form>
            <span className={`${styles.resultMessage} ${getResultClass()}`}>{result}</span>
        </div>
    );
};

export default ContactForm;
