import React from "react";
import styles from "./media.module.css";

import Header from '../organisms/header';
import SocialsBlack from '../molecules/socials/socialsBlack';
import Hamburger from "../molecules/Hamburger/hamb";
import FadeInElement from "../atoms/FadeInElement";

const MediaPage = () => {
    return (
        <div className={styles.media}>
            <Header />
            <FadeInElement>
                <h2 className={styles.heading}>Media</h2>
                <p>More recordings coming soon! Subscribe to my <a target="_blank" rel="noreferrer" href="https://youtube.com/@brandonrokuali7210">YouTube Channel</a> for all the latest.</p>
                <div className={styles.mediaCont}>
                    <iframe
                    title="Kaiser and I, Pt. 1"
                    src="https://www.youtube.com/embed/SoB4cazFekQ?si=St6XS8Fa9SERPWu1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    /> 

                    <iframe
                    title="Kaiser and I, Pt. 2"
                    src="https://www.youtube.com/embed/L1rsfCkwgZA?si=E8IUES7IPmwMunss"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    />

                    <iframe
                    title="Walking on Water"
                    src="https://www.youtube.com/embed/dOvo6qR2qLo?si=1HOh7bK95_us63JE"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                    />
                </div>
            </FadeInElement>
            <SocialsBlack />
            <Hamburger />
        </div>
    );
};

export default MediaPage;