import React from "react";
import styles from "./about.module.css";
import SocialsBlack from "../molecules/socials/socialsBlack";
import Header from "../organisms/header";
import Hamburger from "../molecules/Hamburger/hamb";
import AboutImage from '../../assets/about.jpg';
import FadeInElement from "../atoms/FadeInElement";

const AboutPage = () => {
    return (
        <div className={styles.about}>
            <Header />
            <FadeInElement>
                <h2 className={styles.heading}>About</h2>
                <div className={styles.aboutImgCont}>
                    <img src={AboutImage} alt="Brandon playing the piano" />
                </div>
                <p>
                Amado, well known as <span className={styles.accent}>Brandon Roku Ali</span>, is an Afro-British classical-trained pianist and composer interested in filling the gap between <span className={styles.accent}>classical and contemporary</span> music. He performs anything from Baroque to late Romantic music, and his own compositions explore a wide range of sound characteristics, including classical and film music. <br />
                <br />
                He has also been exploring <span className={styles.accent}>popular music</span> throughout the world to include more sonorities people could relate to. One of his aspirations is to bring a wider range of audiences into the world of live acoustic music, therefore making the art form <span className={styles.accent}>more inclusive.</span></p>

                <h3>Style</h3>

                <p className={styles.gap}>A defining feature of his compositions is <span className={styles.accent}>optimism</span>, which he displays through a choice of major keys, the introduction of sparse melodic lines, and the use of pauses and rhythmic alterations throughout pieces to create a sense of spaciousness; or a borderless room for the mind to <span className={styles.accent}>rejoice in contemplation</span> or escape off to a fairy tale.
                 <br /><br />
                Outside of his music bubble, Brandon considers himself just an <span className={styles.accent}>ordinary person</span> with normal human emotions. As a result, he gets a lot of inspiration from very ordinary things such as <span className={styles.accent}>nature</span> and his moods (love, joy, sorrow, and nostalgia). Music allows him to create a world of his own, free from worries or conflict. He would love to see others find that sort of <span className={styles.accent}>retreat and recharge</span>. As Brandon likes to say:</p>

                <p className={styles.quote}>‘I hope you find peace within my music’.</p>
            </FadeInElement>
            <SocialsBlack />
            <Hamburger />
        </div>
    );
};

export default AboutPage;