import React from "react";
import styles from "./navbar.module.css";
import Menu from "../../atoms/menu";

const Navbar = () => {
    return (
        <nav className={styles.navbar}>
            <Menu />
        </nav>
    );
};

export default Navbar;
