import React from "react";
import Navbar from "../molecules/Navbar/navbar";
import styles from './header.module.css'

const Header = () => {
    return (
        <div className={styles.header}>
            <Navbar /> 
        </div>
    );
};

export default Header;