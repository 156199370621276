import React from "react";
import styles from "./socialsBlack.module.css";

// SOCIAL ICONS

import FB from "../../../assets/socials/fb.png";
import YouTube from "../../../assets/socials/yt.png";
import Insta from "../../../assets/socials/insta.png";
import Soundcloud from "../../../assets/socials/soundcloud.png";
import Apple from "../../../assets/socials/apple.png";
import Spotify from "../../../assets/socials/spotify.png";

const SocialsBlack = () => {
    return (
        <div className={styles.footer}>
            <div className={styles.socials}>
            <a href="https://www.facebook.com/BrandinhoMusic" target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={FB} alt="Facebook Logo" /></a>
                <a href="https://www.instagram.com/brandinho_music/channel/ " target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={Insta} alt="Instagram Logo" /></a>
                <a href="https://www.youtube.com/channel/UCLUygEmoYLiBFiJ2O-S3ZBg" target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={YouTube} alt="YouTube Logo" /></a>
                <a href="https://soundcloud.com/brandon-r-a-875482942?utm_source=clipboard&utm_medium=text&utm_campaign=social_sharing" target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={Soundcloud} alt="Soundcloud Logo" /></a>
                <a href="https://music.apple.com/mx/artist/brandon-roku-ali/1660451355?l=en-GB" target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={Apple} alt="Apple Music Logo" /></a>
                <a href="https://open.spotify.com/artist/2JcAAKc3QjvjjPqluG0q1J?si=1D-P3tpqSdaU_FCsexRH3w" target="_blank" rel="noreferrer"><img className={styles.socialIcon} src={Spotify} alt="Spotify Logo" /></a>
            </div>
        </div>
    );
};

export default SocialsBlack;