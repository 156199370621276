import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

import HeroPage from './components/pages/hero';
import AboutPage from './components/pages/about';
import MediaPage from './components/pages/media';
import ContactPage from './components/pages/contact';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<HeroPage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/media" element={<MediaPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
