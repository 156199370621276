// src/FadeInElement.js
import React from 'react';
import "./FadeInElement.css"; // Import CSS for fade effect

const FadeInElement = ({ children }) => {
  return (
    <div className="fade-in-element">
      {children}
    </div>
  );
};

export default FadeInElement;
